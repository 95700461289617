const content = {
  videos: [
    {
      title: 'Ghostwriters',
      url: 'https://www.youtube.com/watch?v=B7oAaK80BhY',
    },
     {
      title: 'Oh No',
      url: 'https://www.youtube.com/watch?v=CgeZWuohQGU',
    },
    {
      title: 'Ghostwriters (Teaser)',
      url: 'https://youtu.be/X24So9_ZQIM',
    },
        {
      title: 'Next To Me Anyways',
      url: 'https://youtu.be/8PfGeHbJqxY',
    },
         {
      title: 'Maury Povich',
      url: 'https://youtu.be/5Gad_4ja55Q',
    },
        {
      title: 'Same Old Thing',
      url: 'https://youtu.be/oCWPq4rHePM',
    },
    {
      title: 'Overnight Train',
      url: 'https://youtu.be/WupEEiTS2b8',
    },
    {
      title: 'Tag Along',
      url: 'https://youtu.be/_8a4_8SmT7o',
    },
    {
      title: 'I Don\'t\ Know If I\'\ll Make It Anymore',
      url: 'https://youtu.be/_eEh-H1fdCk',
    },
     {
      title: 'I Don\'t\ Know If I\'\ll Make It Anymore (Lyric Video)',
      url: 'https://youtu.be/jY94zHit-Bs',
    },
     {
      title: '341 Chapel',
      url: 'https://www.youtube.com/watch?v=q1skD71lWk0',
    },
    {
      title: 'Tambourine',
      url: 'https://www.youtube.com/watch?v=B3IEB-_8nyQ',
    },
    {
      title: 'Los Angeles Televangelist',
      url: 'https://www.youtube.com/watch?v=Jo45d8GtpAA',
    },
  ],
};

export default content;
