import React from 'react';
import Layout from '../components/Layout';
import YouTubeVideo from "../components/YoutubeVideo";

import content from '../content/videos';

const VideosPage = () => (
  <Layout>
    <div>
      <div className="videos-wrapper">
        {content.videos.map(video => (
          <YouTubeVideo url={video.url} key={video.title} />
        ))}
      </div>
    </div>
  </Layout>
);

export default VideosPage;
