import React from 'react';

class YouTubeVideo extends React.Component {
    getIdFromUrl = url => {
      let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      let match = url.match(regExp);
  
      if (match && match[2].length === 11) {
        return match[2];
      } else {
        return 'error';
      }
    };
  
    render() {
      const id = this.getIdFromUrl(this.props.url);
      const embedUrl = `https://www.youtube.com/embed/${id}?rel=0`;
      return (
        <iframe
          className="video"
          width="560"
          height="315"
          src={embedUrl}
          frameBorder="0"
          allowFullScreen
          title={this.props.title}
        />
      );
    }

  }

export default YouTubeVideo;